import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Icon } from '@aider/ui'
import { StaticImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import AnimateText from '../components/AnimateText'
import Button from '../components/Button'
import Seo from '../components/Seo'

const VIDEO_URL = 'https://vimeo.com/825720920?share=copy'
const VIMEO_CONFIG = {
	vimeo: {
		playerOptions: {
			muted: false,
			autoplay: false,
			loop: true,
			responsive: true,
		},
	},
}

const FEATURES = [
	{
		icon: 'bell',
		title: 'Introduktion ingår',
		text: 'Vill du ha vår hjälp att snabbt komma ingång, ska du boka in dig för en digitala introduktion. Det är kostnadsfria och du har varje vecka flera tillfällen att välja mellan.',
	},
	{
		icon: 'cog',
		title: 'Gratis uppdateringar',
		text: 'Du har alltid tillgång till senaste versionen av Aider. Vi utvecklingar nytt hela tiden och i nära samarbete med alla våra användare runt om i landet.',
	},
	{
		icon: 'quill',
		title: 'Detaljerade redovisningar ',
		text: 'Du får hjälp att enkelt sammanställa din ekonomiska redovisning enligt gällande lagkrav, på ett sätt som accepteras av alla överförmyndare i Sverige.',
	},
	{
		icon: 'shield',
		title: 'BankID och kryptering',
		text: 'Det enda sättet att komma åt din information är genom inloggning via BankID. All information är krypterad för din och dina huvudmäns säkerhet. ',
	},
	{
		icon: 'lock-closed',
		title: 'Lagring på egna servrar',
		text: 'Din information sparas på våra egna servrar i Sverige. Vi har ett högt säkerhetstänk som inte stannar vid vad vi bygger utan även påverkar vilka leverantörer vi väljer att jobba med.',
	},
	{
		icon: 'cloud',
		title: 'Säkerhetskopiering ingår',
		text: 'Allt du lägger in och sparar i Aider säkerhetskopieras automatiskt. Du riskera inte att förlora ditt arbete och kan när som helst begära ut din information med ett enkelt knapptryck.',
	},
]

const Home = () => (
	<Fragment>
		<Seo
			title="Förenkla uppdraget som god man."
			description="Bokföring, dagbok & körjournal, årsräkning med mera. Allt samlat i en flexibel lösning för mobil och dator, skyddat med inloggning via BankID."
		/>

		<div className="container mx-auto">
			{/* Hero Section */}
			<section className="flex flex-col items-center md:w-10/12 lg:w-8/12 px-4 mb-24 lg:mb-48 text-center md:mx-auto pt-16">
				<VideoContainer className="relative w-full mb-8 shadow-lg">
					<div className="overflow-hidden rounded-lg relative z-10 bg-white">
						<ReactPlayer
							width="100%"
							height="100%"
							url={VIDEO_URL}
							playing={false}
							controls={true}
							config={VIMEO_CONFIG}
						/>
					</div>
				</VideoContainer>
				<h1 className="text-3xl mb-6 md:text-5xl lg:text-6xl font-futura font-semibold leading-none md:leading-tight max-w-xs md:max-w-lg lg:max-w-2xl">
					<AnimateText>
						Förenkla ditt uppdrag som god man.
					</AnimateText>
				</h1>
				<p className="text-base md:text-lg lg:text-xl lg:leading-normal text-gray-800 mb-8">
					Det är inte alltid enkelt att vara god man! Men vissa delar
					av uppdraget går bestämt att förenkla, med rätt
					förutsättningar och lite hjälp. Aider är en digital tjänst
					framtagen av gode män för gode män, för att åstadkomma just
					det. Med Aiders hjälp administrerar du smidigt och säkert
					ditt uppdrag. Och vi finns alltid till hands om du skulle
					behöva hjälp på vägen.
				</p>
				<p className="text-base md:text-lg font-medium">Ulf Mattsson</p>
				<p className="text-base md:text-lg">
					God man och grundare av Aider
				</p>
			</section>

			{/* Features Section */}
			<section id="features" className="md:mb-8 px-4 sm:px-6">
				{/* Smart redovisning */}
				<div className="flex flex-wrap lg:items-start 2xl:items-center w-full md:w-10/12 lg:w-11/12 xl:w-9/12 mx-auto mb-12 md:mb-24 lg:mb-48">
					<div className="w-full lg:w-7/12 mb-6 lg:mb-0">
						<FeatureImageContainer className="relative bg-gradient-to-tr from-blue-400 to-blue-600 rounded-lg overflow-hidden">
							<StaticImage
								alt="Bild på rapport"
								src="../images/feature_report.png"
								placeholder="blurred"
								layout="constrained"
							/>
						</FeatureImageContainer>
					</div>
					<div className="w-full lg:w-5/12 lg:px-6">
						<span className="inline-block text-xs sm:text-sm text-blue-500 uppercase font-semibold mb-2">
							Smart redovisning
						</span>
						<h3 className="text-2xl sm:text-3xl 2xl:text-4xl font-futura font-semibold leading-tight mb-4">
							Bokföring har aldrig varit enklare.
						</h3>
						<p className="text-base xl:text-lg text-gray-800 mb-4">
							Du slipper skriva in alla transaktioner för hand. Vår unika funktion att importera data direkt från banker, eliminerar fel och spar mängder med tid i samband med redovisning och rapportering.
						</p>
						<hr
        					style={{
          						background: '#73cc98',
          						color: '#73cc98',
          						borderColor: '#73cc98',
          						height: '4px',
								marginBottom: '20px',
        					}}
      					/>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							"Wow! Du skulle bara veta hur mycket jag älskar detta program! Att kunna hitta fel snabbt och få iväg redovisningar i tid utan att slita sitt hår."
						</p>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							Charlotte Stark - God man, Höganäs
						</p>
					</div>
				</div>
				{/* Snabb support */}
				<div className="flex flex-wrap flex-row-reverse  lg:items-start 2xl:items-center w-full md:w-10/12 lg:w-11/12 xl:w-9/12 mx-auto mb-12 md:mb-24 lg:mb-48">
					<div className="w-full lg:w-7/12 mb-6 lg:mb-0">
						<FeatureImageContainer className="relative bg-gradient-to-tr from-blue-400 to-blue-600 rounded-lg overflow-hidden">
							<StaticImage
								alt="Bild på app och web-app"
								src="../images/feature_iphone_web.png"
								placeholder="blurred"
								layout="constrained"
							/>
						</FeatureImageContainer>
					</div>
					<div className="w-full lg:w-5/12 lg:px-6">
						<span className="inline-block text-xs sm:text-sm text-blue-500 uppercase font-semibold mb-2">
							Snabb support
						</span>
						<h3 className="text-2xl sm:text-3xl 2xl:text-4xl font-futura font-semibold leading-tight mb-4">
							Daglig support till alla användare.
						</h3>
						<p className="text-base xl:text-lg text-gray-800 mb-4">
							Du får tillgång till marknadens enda programvara där daglig support ingår. Ett integrerat hjälpcenter med artikelsamlingar, personlig chatt, telefon och möjlighet till skärmdelning, gör att du snabbt får hjälp när du behöver det.
						</p>
						<hr
        					style={{
          						background: '#73cc98',
          						color: '#73cc98',
          						borderColor: '#73cc98',
          						height: '4px',
								marginBottom: '20px',
        					}}
      					/>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							"Nu är jag med på hur det fungerar. Tack för suverän support!"
						</p>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							Johan Edström - God man, Bandhagen
						</p>
					</div>
				</div>
				{/* Allt ingår */}
				<div className="flex flex-wrap lg:items-start 2xl:items-center w-full md:w-10/12 lg:w-11/12 xl:w-9/12 mx-auto mb-12 md:mb-24 lg:mb-48">
					<div className="w-full lg:w-7/12 mb-6 lg:mb-0">
						<FeatureImageContainer className="relative bg-gradient-to-tr from-blue-400 to-blue-600 rounded-lg overflow-hidden">
							<StaticImage
								alt="Bild på dagbok och körjournal"
								src="../images/feature_journal.png"
								placeholder="blurred"
								layout="constrained"
							/>
						</FeatureImageContainer>
					</div>
					<div className="w-full lg:w-5/12 lg:px-6">
						<span className="inline-block text-xs sm:text-sm text-blue-500 uppercase font-semibold mb-2">
							Allt ingår
						</span>
						<h3 className="text-2xl sm:text-3xl 2xl:text-4xl font-futura font-semibold leading-tight mb-4">
							Säkerhet och uppdateringar.
						</h3>
						<p className="text-base xl:text-lg text-gray-800 mb-4">
							Du behöver inte bekymra dig om uppdateringar och säkerhetskopiering. Allt ingår! Tillsammans med alla våra användare utvecklar vi fortlöpande nya smarta funktioner. Uppdateringar sker automatiskt och vi håller dig alltid underrättad om det senaste via våra nyhetsbrev.
						</p>
						<hr
        					style={{
          						background: '#73cc98',
          						color: '#73cc98',
          						borderColor: '#73cc98',
          						height: '4px',
								marginBottom: '20px',
        					}}
      					/>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							"Tjatar just nu på alla ÖF som inte har Aider, att de måste skaffa!"
						</p>
						<p className="text-base text-xs sm:text-sm text-gray-800 mb-4">
							Lise Pelle - God man och förvaltare, Landskrona
						</p>
					</div>
				</div>
			</section>

			{/* More Features Section */}
			<section className="lg:w-10/12 lg:mx-auto px-4">
				<h3 className="text-2xl sm:text-3xl 2xl:text-4xl font-futura font-semibold leading-tight mb-4 md:px-6">
					Och mycket, mycket mer.
				</h3>
				<div className="flex flex-wrap">
					{FEATURES.map(({ icon, title, text }) => {
						return (
							<div
								key={icon}
								className="w-full md:w-6/12 lg:w-4/12 md:px-6 py-4 md:py-6 lg:py-8"
							>
								<Icon
									name={icon}
									className="fill-current text-blue-500 h-6 w-6 mb-2"
								/>
								<h3 className="text-lg mb-3 font-semibold">
									{title}
								</h3>
								<p className="text-base md:text-lg text-gray-800 mb-4">
									{text}
								</p>
							</div>
						)
					})}
				</div>
			</section>
		</div>

		{/* CTA Section */}
		<CtaContainer className="relative mb-12 overflow-hidden px-4 py-12 mb:py-24 lg:py-48">
			<div className="container mx-auto relative z-10">
				<div className="flex flex-col items-center text-center bg-blue-900 w-full md:w-10/12 lg:w-8/12 xl:w-7/12 md:mx-auto py-8 md:py-12 lg:py-20 px-4 rounded-xl">
					<h3 className="text-3xl mb-4 md:text-4xl lg:text-5xl font-futura font-semibold leading-tight max-w-xs md:max-w-sm lg:max-w-lg text-white">
						Prova Aider nu
					</h3>
					<p className="text-base xl:text-lg text-white mb-6 max-w-sm">
						Registrera dig för kostnadsfria Aider Grund och prova
						Premium i 30 dagar.
					</p>
					<div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0">
						<Button
							title="Kom igång gratis"
							variant="primary"
							href="https://app.aider.nu/skapa-konto"
						/>
						<Button
							title="Se abonnemang"
							variant="secondary"
							to="/pris"
						/>
					</div>
				</div>
			</div>
		</CtaContainer>
	</Fragment>
)

const FeatureImageContainer = styled.div`
	aspect-ratio: 3 / 2;
`

const VideoContainer = styled.div`
	aspect-ratio: 16 / 9;
	&:after {
		content: '';
		transform: scale(0.95) translate(0, -32px);
		${tw`block absolute top-0 left-0 rounded-lg w-full h-full z-0 bg-gradient-to-r from-green-500 to-blue-500`};
	}
`

const CtaContainer = styled.div`
	&:after {
		content: '';
		transform: scale(1.2) skewY(-2.5deg);
		bottom: -25%;
		${tw`bg-gray-200 absolute  left-0 h-3/4 w-full z-0`};
	}
`

export default Home
